import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    isLoading: Subject<boolean> = new Subject<boolean>();
    isMassOperationActive: boolean = false;
    isLastOperation: boolean = false;

    constructor() {
    }

    show(): void {
        this.isLoading.next(true);
    }

    hide(): void {
        if (this.isMassOperationActive === this.isLastOperation) {
            this.isLoading.next(false);
        }
    }
}
